.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.back{
  padding-top: 5px;
  padding-left: 5px;
}

.back a {
  color: black;
  background-color: #c10008;
  display: block;
  width: 15%;
  border: none;
  color: black;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 25px;
}

.resetApp{
  padding-top: 100px;
  padding-left: 5px;
}

.resetApp a {
  color: black;
  background-color: #c10008;
  display: block;
  width: 15%;
  border: none;
  color: black;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 25px;
  margin: auto;
  width: 15%;
  padding: 10px;
}

.btn-check:focus+.btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #c10008;
  border-color: #c10008;
  box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
}

.btn-primary {
  color: #fff;
  background-color: #c10008;
  border-color: #c10008;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #c10008;
  border-color: #c10008; /*set the color you want here*/
}

.flagsDropDown{
  width: 200px;
  margin: auto;
}